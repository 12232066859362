import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CHAT_FEATURE_KEY, ChatState } from './chat.reducer';
import { BuildDirectConversationKey, ChatConversation } from './chat.models';

export const selectChatsState =
  createFeatureSelector<ChatState>(CHAT_FEATURE_KEY);

export const selectLoading = createSelector(
  selectChatsState,
  (state: ChatState) => state.loading,
);

export const selectLoaded = createSelector(
  selectChatsState,
  (state: ChatState) => state.loaded,
);

export const selectError = createSelector(
  selectChatsState,
  (state: ChatState) => state.error,
);

export const selectShowDetails = createSelector(
  selectChatsState,
  (state: ChatState) => state.showDetails,
);

export const selectActiveUser = createSelector(
  selectChatsState,
  (state: ChatState) => state.activeUser,
);

export const selectSelectedConversation = createSelector(
  selectChatsState,
  (state: ChatState) => state.selectedConversation,
);

export const selectUsers = createSelector(
  selectChatsState,
  (state: ChatState) => state.users,
);

export const selectUsersLoading = createSelector(
  selectUsers,
  (users) => users.loading,
);

export const selectUsersLoaded = createSelector(
  selectUsers,
  (users) => users.loaded,
);

export const selectUsersError = createSelector(
  selectUsers,
  (users) => users.error,
);

export const selectUsersList = createSelector(selectUsers, (users) =>
  Object.values(users.entities),
);

export const selectGroups = createSelector(
  selectChatsState,
  (state: ChatState) => state.groups,
);

export const selectGroupsLoading = createSelector(
  selectGroups,
  (groups) => groups.loading,
);

export const selectGroupsLoaded = createSelector(
  selectGroups,
  (groups) => groups.loaded,
);

export const selectGroupsError = createSelector(
  selectGroups,
  (groups) => groups.error,
);

export const selectGroupsList = createSelector(selectGroups, (groups) =>
  Object.values(groups.entities),
);

export const selectDirectConversations = createSelector(
  selectChatsState,
  (state: ChatState) => state.directConversations,
);

export const selectDirectConversationsLoading = createSelector(
  selectDirectConversations,
  (directConversations) => directConversations.loading,
);

export const selectDirectConversationsLoaded = createSelector(
  selectDirectConversations,
  (directConversations) => directConversations.loaded,
);

export const selectDirectConversationsError = createSelector(
  selectDirectConversations,
  (directConversations) => directConversations.error,
);

export const selectDirectConversationsList = createSelector(
  selectDirectConversations,
  (directConversations) => {
    if (!directConversations?.entities) {
      return;
    }
    return Object.values(directConversations.entities)
      .filter(
        (directConversation) =>
          directConversation.meta.display &&
          !directConversation.meta.displayAsBot,
      )
      .map((directConversation) => directConversation.conversation)
      .sort((a: ChatConversation, b: ChatConversation) => {
        return (
          b.getLastMessage()?.getSentAt() - a.getLastMessage()?.getSentAt()
        );
      })
      .sort((a: ChatConversation, b: ChatConversation) => {
        if (b.getUnreadMessageCount() > 0 && a.getUnreadMessageCount() === 0) {
          return 1;
        } else if (
          b.getUnreadMessageCount() === 0 &&
          a.getUnreadMessageCount() > 0
        ) {
          return -1;
        }
        return 0;
      });
  },
);

export const selectBotConversation = (senderUid: string) =>
  createSelector(
    selectActiveUser,
    selectDirectConversations,
    (user, directConversations) => {
      const receiverUid = user?.getUid() || '';
      const uid = BuildDirectConversationKey(receiverUid, senderUid);
      return directConversations?.entities[uid]?.conversation;
    },
  );

export const selectDirectConversation = (uid: string) =>
  createSelector(selectDirectConversations, (directConversations) => {
    return directConversations?.entities[uid]?.conversation;
  });

export const selectGroupConversations = createSelector(
  selectChatsState,
  (state: ChatState) => state.groupConversations,
);

export const selectGroupConversationsLoading = createSelector(
  selectGroupConversations,
  (groupConversations) => groupConversations.loading,
);

export const selectGroupConversationsLoaded = createSelector(
  selectGroupConversations,
  (groupConversations) => groupConversations.loaded,
);

export const selectGroupConversationsError = createSelector(
  selectGroupConversations,
  (groupConversations) => groupConversations.error,
);

export const selectGroupConversationsList = createSelector(
  selectGroupConversations,
  (groupConversations) => {
    if (!groupConversations.entities) {
      return;
    }
    return Object.values(groupConversations.entities)
      .filter((groupConversation) => groupConversation.meta.display)
      .map((groupConversation) => groupConversation.conversation)
      .sort((a, b) => {
        return (
          b.getLastMessage()?.getSentAt() - a.getLastMessage()?.getSentAt()
        );
      })
      .sort((a, b) => {
        if (b.getUnreadMessageCount() > 0 && a.getUnreadMessageCount() === 0) {
          return 1;
        } else if (
          b.getUnreadMessageCount() === 0 &&
          a.getUnreadMessageCount() > 0
        ) {
          return -1;
        }
        return 0;
      });
  },
);

export const selectGroupConversation = (uid: string) =>
  createSelector(
    selectGroupConversations,
    (groupConversations) => groupConversations.entities[uid]?.conversation,
  );

export const selectUnreadMessageCounts = createSelector(
  selectChatsState,
  (state: ChatState) => state.unreadMessageCount,
);

export const selectTotalUnreadMessageCount = createSelector(
  selectChatsState,
  (state: ChatState) => {
    if (!state.unreadMessageCount) return;
    const unreadMessageCount: number[] = Object.values(
      state.unreadMessageCount,
    );
    return unreadMessageCount.reduce((i, count) => i + count, 0);
  },
);

export const selectUnreadMessageCountForConversations = (uids: string[] = []) =>
  createSelector(selectUnreadMessageCounts, (entities) => {
    return uids.map((uid) => entities[uid] || 0);
  });

export const selectUnreadMessageCountForConversation = (uid: string) =>
  createSelector(selectUnreadMessageCounts, (entities) => entities[uid] || 0);

export const selectMessages = createSelector(
  selectChatsState,
  (state: ChatState) => state.messages,
);

export const selectMessagesLoading = createSelector(
  selectMessages,
  (messages) => messages.loading,
);

export const selectMessagesLoaded = createSelector(
  selectMessages,
  (messages) => messages.loaded,
);

export const selectMessagesError = createSelector(
  selectMessages,
  (messages) => messages.error,
);

export const selectMessagesList = createSelector(selectMessages, (messages) =>
  Object.values(messages.entities).sort(
    (a, b) => a.getSentAt() - b.getSentAt(),
  ),
);
