import { inject, Injectable } from '@angular/core';
import { CometChat } from '@cometchat-pro/chat';
import { LoggerService } from '@chassis/shared/services/logger';
import { catchError, from, map, Observable, switchMap, throwError } from 'rxjs';
import { ENVIRONMENT, SharedEnvironment } from '@chassis/shared/environment';
import {
  ChatConversation,
  ChatMessage,
  ConversationType,
} from '../store/chat.models';
import { User } from '@chassis/shared/models';

export interface MessageQuery {
  uid: string;
  parentMessageId?: number;
  hideReplies: boolean;
  limit: number;
}

export enum ErrorCodes {
  ERR_UID_ALREADY_EXISTS = 'ERR_UID_ALREADY_EXISTS',
}

@Injectable()
export class ChatService {
  private env: SharedEnvironment = inject(ENVIRONMENT);
  private logger = inject(LoggerService);

  get messageCategories(): string[] {
    return [
      CometChat.CATEGORY_MESSAGE,
      CometChat.MESSAGE_TYPE.CUSTOM,
      CometChat.CATEGORY_ACTION,
      CometChat.CATEGORY_CALL,
    ];
  }

  get messageTypes(): string[] {
    return [
      CometChat.MESSAGE_TYPE.TEXT,
      CometChat.MESSAGE_TYPE.IMAGE,
      CometChat.MESSAGE_TYPE.VIDEO,
      CometChat.MESSAGE_TYPE.AUDIO,
      CometChat.MESSAGE_TYPE.FILE,
      'extension_poll',
      'extension_sticker',
      'groupMember',
      'DIRECT_CALL',
      CometChat.CALL_TYPE.AUDIO,
      CometChat.CALL_TYPE.VIDEO,
    ];
  }

  private messageRequest: CometChat.MessagesRequest | undefined;

  init(user: User): Observable<CometChat.User> {
    return from(this.initCometChat()).pipe(
      switchMap(() => from(this.createUser(user))),
      switchMap((user) => from(this.authenticate(user))),
    );
  }

  logout(): Observable<boolean> {
    return from(CometChat.logout()).pipe(
      map((): boolean => true),
      catchError((error) => {
        this.logger.error('ChatService.logout', error);
        return throwError(error);
      }),
    );
  }

  loadUsers(friendsOnly = false, limit = 30): Observable<CometChat.User[]> {
    const request = new CometChat.UsersRequestBuilder()
      .friendsOnly(friendsOnly)
      .setLimit(limit)
      .sortBy('status')
      .build();

    return from(request.fetchNext()).pipe(
      catchError((error) => {
        this.logger.error('ChatService.loadUsers', error);
        return throwError(error);
      }),
    );
  }

  getUser(uid: string): Observable<CometChat.User> {
    return from(CometChat.getUser(uid)).pipe(
      catchError((error) => {
        this.logger.error('ChatService.getUser', error);
        return throwError(error);
      }),
    );
  }

  loadGroups(): Observable<CometChat.Group[]> {
    const request = new CometChat.GroupsRequestBuilder().setLimit(30).build();

    return from(request.fetchNext()).pipe(
      catchError((error) => {
        this.logger.error('ChatService.loadGroups', error);
        return throwError(error);
      }),
    );
  }

  loadDirectMessages(limit = 30): Observable<ChatConversation[]> {
    const request = new CometChat.ConversationsRequestBuilder()
      .setConversationType('user')
      .setLimit(limit)
      .build();

    return from(request.fetchNext()).pipe(
      map((conversations) =>
        conversations.map(ChatConversation.fromConversation),
      ),
      catchError((error) => {
        this.logger.error('ChatService.loadDirectMessages', error);
        return throwError(error);
      }),
    );
  }

  loadGroupMessages(limit = 30): Observable<ChatConversation[]> {
    const request = new CometChat.ConversationsRequestBuilder()
      .setConversationType('group')
      .setLimit(limit)
      .build();

    return from(request.fetchNext()).pipe(
      map((conversations) =>
        conversations.map(ChatConversation.fromConversation),
      ),
      catchError((error) => {
        this.logger.error('ChatService.loadGroupMessages', error);
        return throwError(error);
      }),
    );
  }

  loadConversationFromMessage(
    message: CometChat.BaseMessage,
  ): Observable<ChatConversation> {
    return from(
      CometChat.CometChatHelper.getConversationFromMessage(message),
    ).pipe(
      map((conversation) => ChatConversation.fromConversation(conversation)),
      catchError((error) => {
        this.logger.error('ChatService.loadConversationFromMessage', error);
        return throwError(error);
      }),
    );
  }

  createDirectConversation(user: CometChat.User): Observable<ChatConversation> {
    const msg = new CometChat.CustomMessage(
      user.getUid(),
      ConversationType.User,
      'initialization',
      {
        initialization: true,
      },
    );

    return this.sendMessage(msg).pipe(
      switchMap((msg) => this.loadConversationFromMessage(msg)),
      catchError((error) => {
        this.logger.error('ChatService.createDirectConversation', error);
        return throwError(error);
      }),
    );
  }

  joinGroup(
    guid: string,
    type: string,
    password = '',
  ): Observable<CometChat.Group> {
    return from(
      CometChat.joinGroup(guid, type as CometChat.GroupType, password),
    ).pipe(
      catchError((error) => {
        this.logger.error('ChatService.joinGroup', error);
        return throwError(error);
      }),
    );
  }

  resetMessageRequest() {
    this.messageRequest = undefined;
  }

  markAsRead(message: ChatMessage): Observable<boolean> {
    return from(CometChat.markAsRead(message)).pipe(
      map((result) => !!result),
      catchError((error) => {
        this.logger.error('ChatService.markAsRead', error);
        return throwError(error);
      }),
    );
  }

  loadDirectMessageHistory(
    query: MessageQuery,
  ): Observable<CometChat.BaseMessage[]> {
    if (!this.messageRequest) {
      const requestBuilder = new CometChat.MessagesRequestBuilder()
        .setUID(query.uid)
        .setCategories(this.messageCategories)
        .setTypes(this.messageTypes)
        .hideReplies(query.hideReplies)
        .setLimit(query.limit);

      if (query.parentMessageId) {
        requestBuilder.setParentMessageId(query.parentMessageId);
      }

      this.messageRequest = requestBuilder.build();
    }

    return from(this.messageRequest.fetchPrevious()).pipe(
      catchError((error) => {
        this.logger.error('ChatService.loadDirectMessageHistory', error);
        return throwError(error);
      }),
    );
  }

  loadGroupMessageHistory(
    query: MessageQuery,
  ): Observable<CometChat.BaseMessage[]> {
    if (!this.messageRequest) {
      const requestBuilder = new CometChat.MessagesRequestBuilder()
        .setGUID(query.uid)
        .setCategories(this.messageCategories)
        .setTypes(this.messageTypes)
        .hideReplies(query.hideReplies)
        .setLimit(query.limit);

      if (query.parentMessageId) {
        requestBuilder.setParentMessageId(query.parentMessageId);
      }

      this.messageRequest = requestBuilder.build();
    }

    return from(this.messageRequest.fetchPrevious()).pipe(
      catchError((error) => {
        this.logger.error('ChatService.loadGroupMessageHistory', error);
        return throwError(error);
      }),
    );
  }

  sendMessage(
    message: CometChat.BaseMessage,
  ): Observable<CometChat.BaseMessage> {
    const call =
      message instanceof CometChat.MediaMessage
        ? CometChat.sendMediaMessage(message)
        : CometChat.sendMessage(message);
    return from(call).pipe(
      catchError((error) => {
        this.logger.error('ChatService.sendMessage', error);
        return throwError(error);
      }),
    );
  }

  private async initCometChat(): Promise<boolean> {
    try {
      const appSetting = new CometChat.AppSettingsBuilder()
        .subscribePresenceForAllUsers()
        .setRegion(this.env.CHAT_CONFIG.region)
        .enableAutoJoinForGroups(true)
        .build();

      return await CometChat.init(this.env.CHAT_CONFIG.appId, appSetting);
    } catch (error) {
      this.logger.error('ChatService.initCometChat', error);
      throw error;
    }
  }

  private async createUser(user: User): Promise<CometChat.User> {
    const cometUser = this.buildCometChatUser(user);
    try {
      return await CometChat.createUser(
        cometUser,
        this.env.CHAT_CONFIG.authKey,
      );
    } catch (error) {
      if (
        (error as CometChat.CometChatException)?.code ===
        ErrorCodes.ERR_UID_ALREADY_EXISTS
      ) {
        return cometUser;
      }
      this.logger.error('ChatService.createUser', error);
      throw error;
    }
  }

  private async authenticate(user: CometChat.User): Promise<CometChat.User> {
    try {
      return await CometChat.login(user.getUid(), this.env.CHAT_CONFIG.authKey);
    } catch (error) {
      this.logger.error('ChatService.authenticate', error);
      throw error;
    }
  }

  private buildCometChatUser(user: User): CometChat.User {
    const cometUser = new CometChat.User('');
    if (user.id) {
      cometUser.setUid(`apf-user_${user.id}`);
      cometUser.setName(`${user.firstName} ${user.lastName}`);
    } else {
      // Fallback to legacy logic to not break app
      cometUser.setUid(user.email.replace(/[@.\s]/gi, '_'));
      cometUser.setName(user.email);
    }
    return cometUser;
  }
}
