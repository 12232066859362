import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import {
  catchError,
  combineLatestWith,
  debounceTime,
  filter,
  map,
  mergeMap,
  Observable,
  of,
  skipWhile,
  switchMap,
  take,
  throwError,
} from 'rxjs';

import { Action, Store } from '@ngrx/store';
import { CometChat } from '@cometchat-pro/chat';

import * as ChatActions from './chat.actions';
import * as ChatSelectors from './chat.selectors';
import {
  APF_BOT_KEY,
  ChatConversation,
  ChatMessage,
  ChatUser,
  ConversationMeta,
  ConversationType,
  DISPLAYABLE_MESSAGE_TYPE,
  MessageType,
} from './chat.models';
import { ChatService, MessageQuery } from '../services/chat.service';
import {
  AuthSharedActions,
  AuthSharedState,
  selectUser,
} from '@chassis/auth/shared';
import { ChatState } from './chat.reducer';
import { SharedActions } from '@chassis/shared/actions';
import { Entity } from '@chassis/shared/models';

@Injectable()
export class ChatEffects {
  readonly loadMessageDelay = 250;

  private actions$ = inject(Actions);
  private chatService = inject(ChatService);
  private store = inject(Store<ChatState>);
  private authStore = inject(Store<AuthSharedState>);

  globalInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.initPostAuth),
      map(() => ChatActions.initChat()),
    ),
  );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.initChat),
      combineLatestWith(this.authStore.select(selectUser)),
      skipWhile(([, apfUser]) => !apfUser?.id),
      take(1),
      switchMap(([, apfUser]) => {
        return this.chatService.init(apfUser).pipe(
          switchMap((user) => [
            ChatActions.initChatSuccess({ user }),
            ChatActions.loadConversations({
              conversationType: ConversationType.User,
            }),
            ChatActions.loadConversations({
              conversationType: ConversationType.Group,
            }),
          ]),
          catchError((error) => of(ChatActions.initChatFailure({ error }))),
        );
      }),
    ),
  );

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadUsers),
      switchMap((action) => {
        return this.chatService.loadUsers(action.onlyFriends).pipe(
          map((users) => {
            const filtered = users.filter((user) => {
              return user.getRole() !== 'bot';
            });
            return ChatActions.loadUsersSuccess({ users: filtered });
          }),
          catchError((error) => of(ChatActions.loadUsersFailure({ error }))),
        );
      }),
    ),
  );

  loadGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadGroups),
      switchMap(() => {
        return this.chatService.loadGroups().pipe(
          map((groups) => ChatActions.loadGroupsSuccess({ groups })),
          catchError((error) => of(ChatActions.loadGroupsFailure({ error }))),
        );
      }),
    ),
  );

  joinGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.joinGroup),
      switchMap((action) => {
        const { guid, groupType } = action;
        const args: [string, string, string?] = [guid, groupType];
        if (groupType === CometChat.GROUP_TYPE.PASSWORD) {
          args.push('abc-123');
        }
        return this.chatService.joinGroup(...args).pipe(
          map((group) => ChatActions.joinGroupSuccess({ group })),
          catchError((error) => of(ChatActions.joinGroupFailure({ error }))),
        );
      }),
    ),
  );

  loadDirectConversations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadConversations),
      filter((action) => action.conversationType === ConversationType.User),
      concatLatestFrom(() => this.store.select(ChatSelectors.selectActiveUser)),
      switchMap(([, activeUser]) =>
        this.chatService.loadDirectMessages().pipe(
          switchMap((chatConversations) => {
            const conversations = chatConversations.map((conversation) => {
              const meta = this.generateConversationMetaData(
                activeUser,
                conversation,
              );
              return { conversation, meta };
            });

            const actions: Action[] = [
              ChatActions.loadConversationsSuccess({
                conversationType: ConversationType.User,
                conversations,
              }),
              ChatActions.setUnreadMessageCountForConversations({
                conversations: chatConversations,
              }),
            ];

            const botConversation = conversations.find(
              (conversation) => conversation.meta.displayAsBot,
            );

            if (botConversation) {
              actions.push(
                ChatActions.selectConversation({
                  conversation: botConversation.conversation,
                }),
              );
            } else {
              actions.push(
                ChatActions.startBotConversation({ key: APF_BOT_KEY }),
              );
            }
            return actions;
          }),
          catchError((error) => {
            return of(
              ChatActions.loadConversationsFailure({
                conversationType: ConversationType.User,
                error,
              }),
            );
          }),
        ),
      ),
    ),
  );

  loadGroupConversations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadConversations),
      filter((action) => action.conversationType === ConversationType.Group),
      concatLatestFrom(() => this.store.select(ChatSelectors.selectActiveUser)),
      switchMap(([, activeUser]) =>
        this.chatService.loadGroupMessages().pipe(
          switchMap((chatConversations) => {
            const conversations = chatConversations.map((conversation) => {
              const meta = this.generateConversationMetaData(
                activeUser,
                conversation,
              );
              return { conversation, meta };
            });

            return [
              ChatActions.loadConversationsSuccess({
                conversationType: ConversationType.Group,
                conversations,
              }),
              ChatActions.setUnreadMessageCountForConversations({
                conversations: chatConversations,
              }),
            ];
          }),
          catchError((error) => {
            return of(
              ChatActions.loadConversationsFailure({
                conversationType: ConversationType.Group,
                error,
              }),
            );
          }),
        ),
      ),
    ),
  );

  startBotConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.startBotConversation),
      concatLatestFrom(() => this.store.select(ChatSelectors.selectActiveUser)),
      switchMap(([action, activeUser]) => {
        const { key } = action;
        return this.chatService.getUser(key).pipe(
          switchMap((user) => {
            return this.chatService.createDirectConversation(user).pipe(
              switchMap((conversation) => {
                const meta = this.generateConversationMetaData(
                  activeUser,
                  conversation,
                );
                return [
                  ChatActions.loadUsersSuccess({ users: [user] }),
                  ChatActions.loadConversationSuccess({
                    conversationType: ConversationType.User,
                    conversation: { conversation, meta },
                  }),
                  ChatActions.selectConversation({ conversation }),
                ];
              }),
            );
          }),
          catchError((error) => {
            return of(
              ChatActions.loadConversationFailure({
                conversationType: ConversationType.User,
                error,
              }),
            );
          }),
        );
      }),
    ),
  );

  startDirectConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.startDirectConversation),
      concatLatestFrom(() => this.store.select(ChatSelectors.selectActiveUser)),
      switchMap(([action, activeUser]) => {
        const { user } = action;

        return this.chatService.createDirectConversation(user).pipe(
          switchMap((conversation) => {
            const meta = this.generateConversationMetaData(
              activeUser,
              conversation,
            );
            return [
              ChatActions.loadConversationSuccess({
                conversationType: ConversationType.User,
                conversation: { conversation, meta },
              }),
              ChatActions.selectConversation({ conversation }),
            ];
          }),
          catchError((error) => {
            return of(
              ChatActions.loadConversationFailure({
                conversationType: ConversationType.User,
                error,
              }),
            );
          }),
        );
      }),
    ),
  );

  selectConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.selectConversation),
      concatLatestFrom(() => [
        this.store.select(ChatSelectors.selectSelectedConversation),
      ]),
      filter(([action, lastConversation]) => {
        if (!lastConversation) {
          return true;
        }

        const { conversation } = action;

        if (!conversation?.getConversationId()) {
          return true;
        }

        return lastConversation.getSourceUid() !== conversation.getSourceUid();
      }),
      switchMap(([action, lastConversation]) => {
        const { conversation } = action;

        if (lastConversation) {
          const lastUid = lastConversation.getSourceUid();
          if (lastUid) {
            this.removeMessageListeners(lastUid);
          }
        }

        this.chatService.resetMessageRequest();

        if (!conversation) {
          return [
            ChatActions.selectConversationSuccess({ conversation: null }),
            ChatActions.hideDetails(),
          ];
        }

        const uid = conversation.getSourceUid();
        this.addMessageListeners(uid);

        return [
          ChatActions.selectConversationSuccess({ conversation }),
          ChatActions.loadMessages(),
          ChatActions.hideDetails(),
        ];
      }),
    ),
  );

  setUnreadCountForConversations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.setUnreadMessageCountForConversations),
      concatLatestFrom(() => [
        this.store.select(ChatSelectors.selectUnreadMessageCounts),
        this.store.select(ChatSelectors.selectSelectedConversation),
      ]),
      map(([action, unreadCounts, selectedConversation]) => {
        const { conversations } = action;

        const selectedId = selectedConversation?.getConversationId();

        const entities = conversations?.reduce((h: any, conversation: any) => {
          const id = conversation.getConversationId();

          if (id === selectedId) {
            return h;
          }

          const oldCount = unreadCounts[id] || 0;
          const newCount = oldCount + conversation.getUnreadMessageCount();
          return { ...h, [id]: newCount };
        }, {});

        return ChatActions.setUnreadMessageCountSuccess({ entities });
      }),
      catchError((e) => {
        return throwError(e);
      }),
    ),
  );

  setUnreadCountForMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.setUnreadMessageCountForMessage),
      concatLatestFrom(() => [
        this.store.select(ChatSelectors.selectUnreadMessageCounts),
        this.store.select(ChatSelectors.selectSelectedConversation),
      ]),
      map(([action, unreadCounts, selectedConversation]) => {
        const { message } = action;

        const selectedId = selectedConversation?.getConversationId();
        const id = message?.getConversationId();

        const entities: Entity<number> = {};
        if (selectedId !== id && this.isMessageDisplayable(message)) {
          entities[id] = (unreadCounts[id] || 0) + 1;
        }

        return ChatActions.setUnreadMessageCountSuccess({ entities });
      }),
    ),
  );

  clearUnreadMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.markMessagesAsRead),
      switchMap((action) => {
        const { message } = action;
        return this.chatService.markAsRead(message).pipe(
          map(() => ChatActions.markMessagesAsReadSuccess({ message })),
          catchError((error) =>
            of(ChatActions.markMessagesAsReadFailure({ error })),
          ),
        );
      }),
    ),
  );

  loadMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadMessages),
      concatLatestFrom(() =>
        this.store.select(ChatSelectors.selectSelectedConversation),
      ),
      mergeMap(([, conversation]) => {
        if (!conversation) {
          return [
            ChatActions.loadMessagesFailure({
              error: 'No conversation selected',
            }),
          ];
        }

        const uid = conversation.getSourceUid();
        const conversationType = this.getConversationType(conversation);
        const query: MessageQuery = {
          uid,
          parentMessageId: undefined,
          hideReplies: true,
          limit: 100,
        };

        const call =
          conversationType === ConversationType.Group
            ? this.chatService.loadGroupMessageHistory(query)
            : this.chatService.loadDirectMessageHistory(query);

        return call.pipe(
          switchMap((messages) => {
            const actions: Action[] = [
              ChatActions.loadMessagesSuccess({ messages }),
            ];

            if (messages.length) {
              const message = messages[messages.length - 1];
              actions.push(ChatActions.markMessagesAsRead({ message }));
            }
            return actions;
          }),
          catchError((error) => of(ChatActions.loadMessagesFailure({ error }))),
        );
      }),
    ),
  );

  loadPreviousMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadPreviousMessages),
      debounceTime(this.loadMessageDelay),
      map(() => ChatActions.loadMessages()),
    ),
  );

  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.sendMessage),
      switchMap((action) => {
        return this.chatService.sendMessage(action.message).pipe(
          map((message) => ChatActions.sendMessageSuccess({ message })),
          catchError((error) => of(ChatActions.sendMessageFailure({ error }))),
        );
      }),
    ),
  );

  receiveMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.messageReceived),
      concatLatestFrom(
        (
          action: any,
        ): [
          Observable<ChatUser | null>,
          Observable<ChatConversation | null>,
          Observable<ChatConversation>,
        ] => {
          const { message } = action;
          const conversationId = message.getConversationId();
          const conversationType =
            message.getReceiverType() as ConversationType;

          if (conversationType === ConversationType.User) {
            return [
              this.store.select(ChatSelectors.selectActiveUser),
              this.store.select(ChatSelectors.selectSelectedConversation),
              this.store.select(
                ChatSelectors.selectDirectConversation(conversationId),
              ),
            ];
          }

          return [
            this.store.select(ChatSelectors.selectActiveUser),
            this.store.select(ChatSelectors.selectSelectedConversation),
            this.store.select(
              ChatSelectors.selectGroupConversation(conversationId),
            ),
          ];
        },
      ),
      switchMap(
        ([action, activeUser, selectedConversation, existingConversation]) => {
          const { message } = action;

          if (
            selectedConversation?.getConversationId() ===
            message.getConversationId()
          ) {
            return [
              ChatActions.messageReceivedSuccess({ message }),
              ChatActions.setUnreadMessageCountForMessage({ message }),
            ];
          }

          if (existingConversation) {
            if (this.isMessageDisplayable(message)) {
              const conversationId = message.getConversationId();
              const conversationType =
                message.getReceiverType() as ConversationType;

              return [
                ChatActions.updateConversationMeta({
                  conversationType,
                  conversationId,
                  meta: { display: true },
                }),
                ChatActions.setUnreadMessageCountForMessage({ message }),
              ];
            }
            return [];
          }

          const conversationType =
            message.getReceiverType() as ConversationType;

          return this.chatService.loadConversationFromMessage(message).pipe(
            switchMap((conversation) => {
              const meta = this.generateConversationMetaData(
                activeUser,
                conversation,
              );
              return [
                ChatActions.loadConversationSuccess({
                  conversationType,
                  conversation: { conversation, meta },
                }),
                ChatActions.setUnreadMessageCountForMessage({ message }),
              ];
            }),
            catchError((error) => {
              return of(
                ChatActions.loadConversationFailure({
                  conversationType,
                  error,
                }),
              );
            }),
          );
        },
      ),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthSharedActions.logout),
      switchMap(() => {
        return this.chatService.logout().pipe(
          map(() => ChatActions.logoutSuccess()),
          catchError((error) => of(ChatActions.logoutFailure({ error }))),
        );
      }),
    ),
  );

  private generateConversationMetaData(
    activeUser: ChatUser | null,
    conversation: ChatConversation,
  ): ConversationMeta {
    return {
      display: this.shouldConversationDisplay(activeUser, conversation),
      displayAsBot: this.shouldConversationDisplayAsBot(conversation),
    };
  }

  private shouldConversationDisplay(
    activeUser: ChatUser | null,
    conversation: ChatConversation,
  ): boolean {
    const lastMessage: ChatMessage = conversation.getLastMessage();

    if (!lastMessage) {
      return false;
    }

    if (this.isInitializationMessage(lastMessage)) {
      return lastMessage.getSender().getUid() === activeUser?.getUid();
    }

    return true;
  }

  private shouldConversationDisplayAsBot(
    conversation: ChatConversation,
  ): boolean {
    const target = conversation.getConversationWith();
    return target instanceof CometChat.User && target.getUid() === APF_BOT_KEY;
  }

  private getConversationType(
    conversation: ChatConversation,
  ): ConversationType {
    const source = conversation.getConversationWith();
    if (source instanceof CometChat.Group) {
      return ConversationType.Group;
    }
    return ConversationType.User;
  }

  private isMessageDisplayable(message: ChatMessage): boolean {
    return DISPLAYABLE_MESSAGE_TYPE.includes(message.getType() as MessageType);
  }

  private isInitializationMessage(message: ChatMessage): boolean {
    return message.getType() === MessageType.Initialization;
  }

  private handleMessageReceived(message: ChatMessage) {
    this.store.dispatch(ChatActions.messageReceived({ message }));
  }

  private addMessageListeners(listenerId: string) {
    CometChat.addMessageListener(
      listenerId,
      new CometChat.MessageListener({
        onTextMessageReceived: (message: CometChat.TextMessage) =>
          this.handleMessageReceived(message),
        onMediaMessageReceived: (message: CometChat.MediaMessage) =>
          this.handleMessageReceived(message),
        onCustomMessageReceived: (message: CometChat.CustomMessage) => {
          this.handleMessageReceived(message);
          // if(customMessage.type == enums.CALL_TYPE_DIRECT){
          //   this.actionGenerated.emit({
          //     type:enums.INCOMING_DIRECT_CALL,
          //     payLoad:customMessage
          //   })
          // }
        },
        // onMessagesRead: (receipt: CometChat.MessageReceipt) => {},
        // onMessageDeleted: (message: CometChat.BaseMessage) => {},
        // onMessageEdited: (message: CometChat.BaseMessage) => {},
        // onMessagesDelivered: (message: CometChat.BaseMessage) => {},
        // onTransientMessageReceived: (message: CometChat.BaseMessage) => {},
        // onTypingEnded: (message: CometChat.BaseMessage) => {},
        // onTypingStarted: (message: CometChat.BaseMessage) => {},
      }),
    );

    /*  TODO - Add Group Listeners
    CometChat.addGroupListener(
      this.groupListenerId,
      new CometChat.GroupListener({
        onGroupMemberScopeChanged: (
          message: null | undefined,
          changedUser: any,
          newScope: any,
          oldScope: any,
          changedGroup: null | undefined
        ) => {
          this.messageUpdated(
            enums.GROUP_MEMBER_SCOPE_CHANGED,
            message,
            changedGroup,
            { user: changedUser, scope: newScope }
          );
        },
        onGroupMemberKicked: (message: null | undefined, kickedUser: any, kickedBy: any, kickedFrom: null | undefined) => {
          this.messageUpdated(
            enums.GROUP_MEMBER_KICKED,
            message,
            kickedFrom,
            {
              user: kickedUser,
              hasJoined: false,
            }
          );
        },
        onGroupMemberBanned: (message: null | undefined, bannedUser: any, bannedBy: any, bannedFrom: null | undefined) => {
          this.messageUpdated(
            enums.GROUP_MEMBER_BANNED,
            message,
            bannedFrom,
            {
              user: bannedUser,
            }
          );
        },
        onGroupMemberUnbanned: (
          message: null | undefined,
          unbannedUser: any,
          unbannedBy: any,
          unbannedFrom: null | undefined
        ) => {
          this.messageUpdated(
            enums.GROUP_MEMBER_UNBANNED,
            message,
            unbannedFrom,
            { user: unbannedUser }
          );
        },
        onMemberAddedToGroup: (
          message: null | undefined,
          userAdded: any,
          userAddedBy: any,
          userAddedIn: null | undefined
        ) => {
          this.messageUpdated(
            enums.GROUP_MEMBER_ADDED,
            message,
            userAddedIn,
            {
              user: userAdded,
              hasJoined: true,
            }
          );
        },
        onGroupMemberLeft: (message: any, leavingUser: any, group: any) => {
          this.messageUpdated(enums.GROUP_MEMBER_LEFT, message, group, {
            user: leavingUser,
          });
        },
        onGroupMemberJoined: (message: any, joinedUser: any, joinedGroup: any) => {
          this.messageUpdated(
            enums.GROUP_MEMBER_JOINED,
            message,
            joinedGroup,
            {
              user: joinedUser,
            }
          );
        },
      })
    );*/

    /*  TODO - Add Call Listeners
      CometChat.addCallListener(
          this.callListenerId,
          new CometChat.CallListener({
            onIncomingCallReceived: (call: any) => {
              this.messageUpdated(enums.INCOMING_CALL_RECEIVED, call);
            },
            onIncomingCallCancelled: (call: any) => {
              this.messageUpdated(enums.INCOMING_CALL_CANCELLED, call);
            },
            onOutgoingCallAccepted: (call: any) => {
              this.messageUpdated(enums.OUTGOING_CALL_ACCEPTED, call);
            },
            onOutgoingCallRejected: (call: any) => {
              this.messageUpdated(enums.OUTGOING_CALL_REJECTED, call);
            },
          })
        );*/
  }

  private removeMessageListeners(listenerId: string) {
    CometChat.removeMessageListener(listenerId);
    // TODO - Remove Group Listeners
    //  CometChat.removeGroupListener(listenerId);

    // TODO - Remove Call Listeners
    // CometChat.removeCallListener(listenerId);
  }
}
