import { Action, createReducer, on } from '@ngrx/store';

import * as ChatActions from './chat.actions';
import {
  ChatConversation,
  ChatError,
  ChatGroup,
  ChatMessage,
  ChatUser,
  Conversation,
  ConversationType,
  LoadableEntitySet,
} from './chat.models';
import { Entity } from '@chassis/shared/models';

export const CHAT_FEATURE_KEY = 'chat';

export interface ChatState {
  activeUser: ChatUser | null;
  showDetails: boolean;
  loading: boolean;
  loaded: boolean;
  error?: ChatError;
  users: LoadableEntitySet<ChatUser>;
  groups: LoadableEntitySet<ChatGroup>;

  directConversations: LoadableEntitySet<Conversation>;
  groupConversations: LoadableEntitySet<Conversation>;
  unreadMessageCount: Entity<number>;
  selectedConversation: ChatConversation | null;

  messages: LoadableEntitySet<ChatMessage>;
}

export interface ChatPartialState {
  readonly [CHAT_FEATURE_KEY]: ChatState;
}

const initialEntityState = () => {
  return { loaded: false, loading: false, error: null, entities: {} };
};

export const initialChatState: ChatState = {
  activeUser: null,
  showDetails: false,
  loading: false,
  loaded: false,
  error: null,

  users: initialEntityState(),
  groups: initialEntityState(),

  directConversations: initialEntityState(),
  groupConversations: initialEntityState(),
  unreadMessageCount: {},
  selectedConversation: null,

  messages: initialEntityState(),
};

const reduceConversations = (
  items: Conversation[],
  entities: Entity<Conversation> = {},
): Entity<Conversation> => {
  return items.reduce((h, item) => {
    return { ...h, [item.conversation.getConversationId()]: item };
  }, entities);
};

const reduceUsers = (
  items: ChatUser[],
  entities: Entity<ChatUser> = {},
): Entity<ChatUser> => {
  return items.reduce((h, item) => {
    return { ...h, [item.getUid()]: item };
  }, entities);
};

const reduceGroups = (
  items: ChatGroup[],
  entities: Entity<ChatGroup> = {},
): Entity<ChatGroup> => {
  return items.reduce((h, item) => {
    return { ...h, [item.getGuid()]: item };
  }, entities);
};

const reduceMessages = (
  items: ChatMessage[],
  entities: Entity<ChatMessage> = {},
): Entity<ChatMessage> => {
  return items.reduce((h, item) => {
    return { ...h, [item.getId()]: item };
  }, entities);
};

const reducer = createReducer(
  initialChatState,
  on(ChatActions.initChat, (state) => {
    return { ...state, loading: true, loaded: false, error: null };
  }),
  on(ChatActions.initChatSuccess, (state, { user }) => {
    return {
      ...state,
      activeUser: user,
      loading: false,
      loaded: true,
      error: null,
    };
  }),
  on(ChatActions.initChatFailure, (state, { error }) => {
    return { ...state, loading: false, loaded: true, error };
  }),

  on(ChatActions.loadUsers, (state) => {
    return {
      ...state,
      users: {
        loaded: false,
        loading: true,
        error: null,
        entities: {},
      },
    };
  }),
  on(ChatActions.loadUsersSuccess, (state, { users }) => {
    return {
      ...state,
      users: {
        loaded: true,
        loading: false,
        error: null,
        entities: reduceUsers(users, state.users.entities),
      },
    };
  }),
  on(ChatActions.loadUsersFailure, (state, { error }) => {
    return {
      ...state,
      users: {
        loaded: true,
        loading: false,
        error,
        entities: {},
      },
    };
  }),

  on(ChatActions.loadConversations, (state, { conversationType }) => {
    switch (conversationType) {
      case ConversationType.Group:
        return {
          ...state,
          groupConversations: {
            loaded: false,
            loading: true,
            error: null,
            entities: {},
          },
        };
      case ConversationType.User:
        return {
          ...state,
          directConversations: {
            loaded: false,
            loading: true,
            error: null,
            entities: {},
          },
        };
      default:
        return state;
    }
  }),
  on(
    ChatActions.loadConversationsSuccess,
    (state, { conversationType, conversations }) => {
      switch (conversationType) {
        case ConversationType.Group:
          return {
            ...state,
            groupConversations: {
              loaded: true,
              loading: false,
              error: null,
              entities: reduceConversations(conversations),
            },
          };
        case ConversationType.User:
          return {
            ...state,
            directConversations: {
              loaded: true,
              loading: false,
              error: null,
              entities: reduceConversations(conversations),
            },
          };
        default:
          return state;
      }
    },
  ),
  on(
    ChatActions.loadConversationsFailure,
    (state, { conversationType, error }) => {
      switch (conversationType) {
        case ConversationType.Group:
          return {
            ...state,
            groupConversations: {
              loaded: true,
              loading: false,
              error,
              entities: {},
            },
          };
        case ConversationType.User:
          return {
            ...state,
            directConversations: {
              ...state.directConversations,
              loaded: true,
              loading: false,
              error,
              entities: {},
            },
          };
        default:
          return state;
      }
    },
  ),

  on(ChatActions.loadConversation, (state, { conversationType }) => {
    switch (conversationType) {
      case ConversationType.Group:
        return {
          ...state,
          groupConversations: {
            ...state.groupConversations,
            loaded: false,
            loading: true,
            error: null,
          },
        };
      case ConversationType.User:
        return {
          ...state,
          directConversations: {
            ...state.directConversations,
            loaded: false,
            loading: true,
            error: null,
          },
        };
      default:
        return state;
    }
  }),
  on(
    ChatActions.loadConversationSuccess,
    (state, { conversationType, conversation }) => {
      switch (conversationType) {
        case ConversationType.Group:
          return {
            ...state,
            groupConversations: {
              loaded: true,
              loading: false,
              error: null,
              entities: reduceConversations(
                [conversation],
                state.groupConversations.entities,
              ),
            },
          };
        case ConversationType.User:
          return {
            ...state,
            directConversations: {
              loaded: true,
              loading: false,
              error: null,
              entities: reduceConversations(
                [conversation] as Conversation[],
                state.directConversations.entities,
              ),
            },
          };
        default:
          return state;
      }
    },
  ),
  on(
    ChatActions.loadConversationFailure,
    (state, { conversationType, error }) => {
      switch (conversationType) {
        case ConversationType.Group:
          return {
            ...state,
            groupConversations: {
              ...state.groupConversations,
              loaded: true,
              loading: false,
              error,
            },
          };
        case ConversationType.User:
          return {
            ...state,
            directConversations: {
              ...state.directConversations,
              loaded: true,
              loading: false,
              error,
            },
          };
        default:
          return state;
      }
    },
  ),

  on(ChatActions.selectConversationSuccess, (state, { conversation }) => {
    return {
      ...state,
      selectedConversation: conversation,
      messages: {
        loaded: false,
        loading: false,
        error: null,
        entities: {},
      },
    };
  }),

  on(
    ChatActions.updateConversationMeta,
    (state, { conversationType, conversationId, meta }) => {
      let conversation: Conversation;
      switch (conversationType) {
        case ConversationType.Group:
          conversation = {
            ...state.groupConversations.entities[conversationId],
            meta: {
              ...state.groupConversations.entities[conversationId].meta,
              ...meta,
            },
          };
          return {
            ...state,
            groupConversations: {
              ...state.groupConversations,
              entities: reduceConversations(
                [conversation],
                state.groupConversations.entities,
              ),
            },
          };
        case ConversationType.User:
          conversation = {
            ...state.directConversations.entities[conversationId],
            meta: {
              ...state.directConversations.entities[conversationId].meta,
              ...meta,
            },
          };
          return {
            ...state,
            directConversations: {
              ...state.directConversations,
              entities: reduceConversations(
                [conversation],
                state.directConversations.entities,
              ),
            },
          };
        default:
          return state;
      }
    },
  ),

  on(ChatActions.setUnreadMessageCountSuccess, (state, { entities }) => {
    return {
      ...state,
      unreadMessageCount: { ...state.unreadMessageCount, ...entities },
    };
  }),
  on(ChatActions.markMessagesAsReadSuccess, (state, { message }) => {
    const uid = message.getConversationId();
    return {
      ...state,
      unreadMessageCount: {
        ...state.unreadMessageCount,
        [uid]: 0,
      },
    };
  }),

  on(ChatActions.loadGroups, (state) => {
    return {
      ...state,
      groups: {
        loaded: false,
        loading: true,
        error: null,
        entities: {},
      },
    };
  }),
  on(ChatActions.loadGroupsSuccess, (state, { groups }) => {
    return {
      ...state,
      groups: {
        loaded: true,
        loading: false,
        error: null,
        entities: reduceGroups(groups),
      },
    };
  }),
  on(ChatActions.loadGroupsFailure, (state, { error }) => {
    return {
      ...state,
      groups: {
        loaded: true,
        loading: false,
        error,
        entities: {},
      },
    };
  }),

  on(ChatActions.loadMessages, (state) => {
    return {
      ...state,
      messages: {
        ...state.messages,
        loaded: false,
        loading: true,
        error: null,
      },
    };
  }),
  on(ChatActions.loadMessagesSuccess, (state, { messages }) => {
    const messageState = { ...state.messages };
    const entities = reduceMessages(messages, state.messages.entities);
    return {
      ...state,
      messages: {
        ...messageState,
        loaded: true,
        loading: false,
        entities,
      },
    };
  }),
  on(ChatActions.loadMessagesFailure, (state, { error }) => {
    const messageState = { ...state.messages };
    return {
      ...state,
      messages: {
        ...messageState,
        loaded: true,
        loading: false,
        error,
      },
    };
  }),

  on(
    ...[ChatActions.messageReceivedSuccess, ChatActions.sendMessageSuccess],
    (state, { message }) => {
      if (
        state.selectedConversation?.getConversationId() !==
        message.getConversationId()
      ) {
        return state;
      }

      const entities = {
        ...state.messages.entities,
        [message.getId()]: message,
      };
      return { ...state, messages: { ...state.messages, entities } };
    },
  ),

  // Messages Panel reducers
  on(ChatActions.selectChat, (state) => {
    return {
      ...state,
      messages: { loaded: false, loading: true, error: null, entities: {} },
    };
  }),
  on(ChatActions.toggleDetails, (state) => ({
    ...state,
    showDetails: !state.showDetails,
  })),
  on(ChatActions.hideDetails, (state) => ({ ...state, showDetails: false })),
);

export function chatReducer(state: ChatState | undefined, action: Action) {
  return reducer(state, action);
}
