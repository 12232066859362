import { createAction, props } from '@ngrx/store';
import {
  ChatError,
  ChatGroup,
  ChatMessage,
  ChatUser,
  ChatConversation,
  ConversationType,
  Conversation,
  ConversationMeta,
} from './chat.models';

export enum Type {
  InitChat = '[Chat] Init',
  InitChatSuccess = '[Chat] Init Success',
  InitChatFailure = '[Chat] Init Failure',
  LogoutSuccess = '[Chat] Logout Success',
  LogoutFailure = '[Chat] Logout Failure',

  LoadUsers = '[Chat] Load Users',
  LoadUsersSuccess = '[Chat] Load Users Success',
  LoadUsersFailure = '[Chat] Load Users Failure',

  LoadConversations = '[Chat] Load Conversations',
  LoadConversationsSuccess = '[Chat] Load Conversations Success',
  LoadConversationsFailure = '[Chat] Load Conversations Failure',

  LoadConversation = '[Chat] Load Conversation',
  LoadConversationSuccess = '[Chat] Load Conversation Success',
  LoadConversationFailure = '[Chat] Load Conversation Failure',

  StartBotConversation = '[Chat] Start Bot Conversation',
  StartDirectConversation = '[Chat] Start Direct Conversation',

  UpdateConversationMeta = '[Chat] Update Conversation Meta',

  SetUnreadMessageCountForConversations = '[Chat] Set Unread Message Count For Conversations',
  SetUnreadMessageCountForMessage = '[Chat] Set Unread Message Count For Message',
  SetUnreadMessageCountSuccess = '[Chat] Set Unread Message Count Success',

  MarkMessagesAsRead = '[Chat] Mark Messages As Read',
  MarkMessagesAsReadSuccess = '[Chat] Mark Messages As Read Success',
  MarkMessagesAsReadFailure = '[Chat] Mark Messages As Read Failure',

  SelectConversation = '[Chat] Select Conversation',
  SelectConversationSuccess = '[Chat] Select Conversation Success',

  LoadMessages = '[Chat] Load Messages',
  LoadMessagesSuccess = '[Chat] Load Messages Success',
  LoadMessagesFailure = '[Chat] Load Messages Failure',

  LoadPreviousMessages = '[Chat] Load Previous Messages',

  SendMessage = '[Chat] Send Message',
  SendMessageSuccess = '[Chat] Send Message Success',
  SendMessageFailure = '[Chat] Send Message Failure',

  MessageReceived = '[Chat] Message Received',
  MessageReceivedSuccess = '[Chat] Message Received Success',
  MessageReceivedFailure = '[Chat] Message Received Failure',

  LoadGroups = '[Chat] Load Groups',
  LoadGroupsSuccess = '[Chat] Load Groups Success',
  LoadGroupsFailure = '[Chat] Load Groups Failure',

  JoinGroup = '[Chat] Join Group',
  JoinGroupSuccess = '[Chat] Join Group Success',
  JoinGroupFailure = '[Chat] Join Group Failure',

  ToggleDetails = '[Chat] Toggle Details',
  HideDetails = '[Chat] Hide Details',
  SelectChat = '[Chat] Select Chat',
}

export const initChat = createAction(Type.InitChat);
export const initChatSuccess = createAction(
  Type.InitChatSuccess,
  props<{ user: ChatUser }>(),
);
export const initChatFailure = createAction(
  Type.InitChatFailure,
  props<{ error: ChatError }>(),
);

export const logoutSuccess = createAction(Type.LogoutSuccess);

export const logoutFailure = createAction(
  Type.LogoutFailure,
  props<{ error: ChatError }>(),
);

export const loadUsers = createAction(
  Type.LoadUsers,
  props<{ onlyFriends: boolean | undefined }>(),
);
export const loadUsersSuccess = createAction(
  Type.LoadUsersSuccess,
  props<{ users: ChatUser[] }>(),
);
export const loadUsersFailure = createAction(
  Type.LoadUsersFailure,
  props<{ error: ChatError }>(),
);

export const loadGroups = createAction(Type.LoadGroups);
export const loadGroupsSuccess = createAction(
  Type.LoadGroupsSuccess,
  props<{ groups: ChatGroup[] }>(),
);
export const loadGroupsFailure = createAction(
  Type.LoadGroupsFailure,
  props<{ error: ChatError }>(),
);

export const joinGroup = createAction(
  Type.JoinGroup,
  props<{ guid: string; groupType: string; password?: string }>(),
);
export const joinGroupSuccess = createAction(
  Type.JoinGroupSuccess,
  props<{ group: ChatGroup }>(),
);
export const joinGroupFailure = createAction(
  Type.JoinGroupFailure,
  props<{ error: ChatError }>(),
);

export const loadConversations = createAction(
  Type.LoadConversations,
  props<{ conversationType: ConversationType }>(),
);
export const loadConversationsSuccess = createAction(
  Type.LoadConversationsSuccess,
  props<{
    conversationType: ConversationType;
    conversations: Conversation[];
  }>(),
);
export const loadConversationsFailure = createAction(
  Type.LoadConversationsFailure,
  props<{ conversationType: ConversationType; error: ChatError }>(),
);

export const loadConversation = createAction(
  Type.LoadConversation,
  props<{ conversationType: ConversationType; conversationId: string }>(),
);
export const loadConversationSuccess = createAction(
  Type.LoadConversationSuccess,
  props<{
    conversationType: ConversationType;
    conversation: Conversation;
  }>(),
);
export const loadConversationFailure = createAction(
  Type.LoadConversationFailure,
  props<{ conversationType: ConversationType; error: ChatError }>(),
);

export const startBotConversation = createAction(
  Type.StartBotConversation,
  props<{ key: string }>(),
);
export const startDirectConversation = createAction(
  Type.StartDirectConversation,
  props<{ user: ChatUser }>(),
);

export const updateConversationMeta = createAction(
  Type.UpdateConversationMeta,
  props<{
    conversationType: ConversationType;
    conversationId: string;
    meta: Partial<ConversationMeta>;
  }>(),
);

export const setUnreadMessageCountForConversations = createAction(
  Type.SetUnreadMessageCountForConversations,
  props<{ conversations: ChatConversation[] }>(),
);

export const setUnreadMessageCountForMessage = createAction(
  Type.SetUnreadMessageCountForMessage,
  props<{ message: ChatMessage }>(),
);

export const setUnreadMessageCountSuccess = createAction(
  Type.SetUnreadMessageCountSuccess,
  props<{ entities: { [uid: string]: number } }>(),
);

export const markMessagesAsRead = createAction(
  Type.MarkMessagesAsRead,
  props<{ message: ChatMessage }>(),
);

export const markMessagesAsReadSuccess = createAction(
  Type.MarkMessagesAsReadSuccess,
  props<{ message: ChatMessage }>(),
);

export const markMessagesAsReadFailure = createAction(
  Type.MarkMessagesAsReadFailure,
  props<{ error: ChatError }>(),
);

export const selectConversation = createAction(
  Type.SelectConversation,
  props<{ conversation: ChatConversation | null }>(),
);

export const selectConversationSuccess = createAction(
  Type.SelectConversationSuccess,
  props<{ conversation: ChatConversation | null }>(),
);

export const loadMessages = createAction(Type.LoadMessages);
export const loadMessagesSuccess = createAction(
  Type.LoadMessagesSuccess,
  props<{ messages: ChatMessage[] }>(),
);
export const loadMessagesFailure = createAction(
  Type.LoadMessagesFailure,
  props<{ error: ChatError }>(),
);

export const loadPreviousMessages = createAction(Type.LoadPreviousMessages);

export const toggleDetails = createAction(Type.ToggleDetails);

export const hideDetails = createAction(Type.HideDetails);

export const selectChat = createAction(
  Type.SelectChat,
  props<{ source: ChatUser | ChatGroup }>(),
);

export const sendMessage = createAction(
  Type.SendMessage,
  props<{ message: ChatMessage }>(),
);
export const sendMessageSuccess = createAction(
  Type.SendMessageSuccess,
  props<{ message: ChatMessage }>(),
);
export const sendMessageFailure = createAction(
  Type.SendMessageFailure,
  props<{ error: ChatError }>(),
);

export const messageReceived = createAction(
  Type.MessageReceived,
  props<{ message: ChatMessage }>(),
);
export const messageReceivedSuccess = createAction(
  Type.MessageReceivedSuccess,
  props<{ message: ChatMessage }>(),
);
export const messageReceivedFailure = createAction(
  Type.MessageReceivedFailure,
  props<{ error: ChatError }>(),
);
