/**
 * Interface for the 'Chat' data
 */
import { CometChat } from '@cometchat-pro/chat';
import { TuiValidationError } from '@taiga-ui/cdk';
import { Entity } from '@chassis/shared/models';

export enum ConversationType {
  Bot = 'bot',
  User = 'user',
  Group = 'group',
}

export enum MessageType {
  Action = 'action',
  Audio = 'audio',
  Custom = 'custom',
  Deleted = 'deleted',
  File = 'file',
  Image = 'image',
  Initialization = 'initialization',
  Text = 'text',
  Video = 'video',
}

export const DISPLAYABLE_MESSAGE_TYPE = [
  MessageType.Audio,
  MessageType.File,
  MessageType.Image,
  MessageType.Text,
  MessageType.Video,
];

export const APF_BOT_KEY = 'apf_bot';

export const BuildDirectConversationKey = (
  receiverId: string,
  senderId: string,
): string => {
  return `${receiverId}_user_${senderId}`;
};

export type ChatUser = CometChat.User;
export type ChatGroup = CometChat.Group;

export class ChatConversation extends CometChat.Conversation {
  static fromConversation(
    cometConversation: CometChat.Conversation,
  ): ChatConversation {
    return new ChatConversation(
      cometConversation.getConversationId(),
      cometConversation.getConversationType(),
      cometConversation.getLastMessage(),
      cometConversation.getConversationWith(),
      cometConversation.getUnreadMessageCount(),
      cometConversation.getTags(),
    );
  }

  getSourceUid(): string {
    const source = this.getConversationWith();
    if (source instanceof CometChat.Group) {
      return source.getGuid();
    }
    return source.getUid();
  }

  equals(conversation: CometChat.Conversation): boolean {
    return this.getConversationId() === conversation.getConversationId();
  }
}

export interface ConversationMeta {
  displayAsBot?: boolean;
  display: boolean;
}

export interface Conversation {
  conversation: ChatConversation;
  meta: ConversationMeta;
}

export interface UserMetadata {
  isBot?: boolean;
}

export type ChatMessage = CometChat.BaseMessage;
export type ChatActionMessage = CometChat.Action;
export type ChatTextMessage = CometChat.TextMessage;
export type ChatMediaMessage = CometChat.MediaMessage;

export type ChatError = TuiValidationError | string | null;

export interface LoadableEntitySet<T> {
  loading: boolean;
  loaded: boolean;
  error: ChatError;
  entities: Entity<T>;
}
